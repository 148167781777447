<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="faculty_code" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="faculty_code"
                                :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="program_code" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('program')">
                            <program-selectbox v-model="formData.program_code"
                                :faculty_code="faculty_code"
                                :validate-error="errors[0]"
                                :is_faculty_code_required="true"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="semester" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('semester')">
                            <semesters-selectbox :validate-error="errors[0]"
                                v-model="formData.semester_id">
                            </semesters-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="class" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('class')">
                            <parameter-selectbox :validate-error="errors[0]"
                                code="classes"
                                v-model="formData.class"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="quota_type" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('application_types')">
                            <parameter-selectbox :validate-error="errors[0]"
                                :multiple="true"
                                code="undergraduate_transfer_quota_types"
                                v-model="formData.quota_type"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="quota" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('quota')">
                            <b-form-input type="number"
                                min="0" max="100"
                                v-model="formData.quota"
                                :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-5">
                <b-button variant="primary"
                    @click="updateForm"
                    :disabled="formLoading">
                        {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Component
    import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox'
    import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox'
    import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox'
    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox'

    // Services
    import UndergraduateTransferQuotaService from '@/services/UndergraduateTransferQuotaService'

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        components: {
            FacultySelectbox,
            ProgramSelectbox,
            SemestersSelectbox,
            ParameterSelectbox,

            ValidationProvider,
            ValidationObserver
        },
        props: {
            formId: {
                type: Number
            }
        },
        data() {
            return {
                formLoading: false,
                faculty_code: null,
                formData: {
                    program_code: null,
                    semester_id: null,
                    quota_type: null,
                    quota: null
                }
            }
        },
        async created() {
            await this.get()
        },
        methods: {
            get() {
                UndergraduateTransferQuotaService.get(this.formId)
                                            .then(response => {
                                                let data = response.data.data

                                                this.faculty_code = data.program.faculty_code
                                                this.formData = data
                                            })
                                            .catch(() => {
                                                this.$router.push('/404')
                                            });
            },

            async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    this.formLoading = true;
                    UndergraduateTransferQuotaService.update(this.formId, this.formData)
                                                .then(response => {
                                                    this.$emit('updateFormSuccess', true);
                                                    this.$toast.success(this.$t('api.' + response.data.message));
                                                })
                                                .catch(e => {
                                                    this.showErrors(e, this.$refs.formModalValidate);
                                                })
                                                .finally(() => {
                                                    this.formLoading = false
                                                })
                }
            }
        }

    }
</script>
