<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-form-group :label="$t('faculty')">
                    <div class="label-as-input">
                        {{ formData.faculty ? getLocaleText(formData.faculty, 'name') : '-' }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group :label="$t('program')">
                    <div class="label-as-input">
                        {{ formData.program ? getLocaleText(formData.program, 'name') : '-' }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('semester')">
                    <div class="label-as-input">
                        {{ formData.semester ? formData.semester.academic_year + ' / ' + getSemester(formData.semester.semester) : '-' }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('class')">
                    <div class="label-as-input">
                        {{ isNotNullValue(formData.class) }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('application_types')">
                    <div class="label-as-input">
                        {{ getLocaleText(formData, 'quota_type_name', '-') }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('quota')">
                    <div class="label-as-input">
                        {{ isNotNullValue(formData.quota) }}
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    // Services
    import UndergraduateTransferQuotaService from '@/services/UndergraduateTransferQuotaService'

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        components: {
            ValidationProvider,
            ValidationObserver
        },
        props: {
            formId: {
                type: Number
            }
        },
        data() {
            return {
                formData: null
            }
        },
        async created() {
            await this.get()
        },
        methods: {
            get() {
                UndergraduateTransferQuotaService.get(this.formId)
                                                 .then(response => {
                                                     this.formData = response.data.data
                                                 })
                                                 .catch((e) => {
                                                     this.$router.push('/404')
                                                 });
            }
        }
    }
</script>
